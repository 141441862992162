<template>
  <v-container fluid class="mb-10">
    <v-row style="width: 96%">
      <v-col cols="12">
        <v-card>
          <v-row class="px-10 mx-10">
            <v-col cols="12">
              <v-card class="" elevation="0">
                <v-row >
                  <v-col>
                    <v-autocomplete
                      :items="all_expenses"
                      :filter="customFilter"
                      v-model="expense"
                      @change="onSelect"
                      return-object
                      solo
                      item-text="expense_name"
                      label="Search Expenses..."
                      prepend-inner-icon="mdi-magnify"
                      style="width: 500px"
                    ></v-autocomplete>
                  </v-col>
                </v-row>
                <v-simple-table>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left font-weight-bold">S/N</th>
                        <th class="text-left font-weight-bold">Name</th>
                        <th class="text-center font-weight-bold">Amount</th>
                         <th class="text-center font-weight-bold">Note</th>
                        <th class="text-center font-weight-bold">
                          <v-icon>mdi-dots-vertical</v-icon>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(item, index) in addedd_expenses" :key="index"  :class="{'blue-grey lighten-4' : index%2 == 0}">
                        <td>{{ index+1 }}</td>
                        <td>{{ item.expense_name }}</td>
                        <td class="text-center">
                          <input
                            type="number"
                            class="amount_input"
                            v-model="item.amount"
                          />
                        </td>
                         <td class="text-center">
                          <input
                            type="text"
                            class="amount_input"
                            v-model="item.input_note"
                          />
                        </td>
                        <td class="text-center">
                          <v-btn
                            icon
                            color="red"
                            dark
                            @click.prevent="onDeleteExpense(item)"
                          >
                            <v-icon medium> mdi-delete </v-icon>
                          </v-btn>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
                <v-card-actions class="mr-10" v-show="addedd_expenses.length">
                  <v-spacer></v-spacer>
                  <v-btn
                    color="primary"
                    elevation="0"
                    class=""
                    @click="onCreateVoucher"
                    style="width : 150px"
                    >Submit</v-btn
                  >
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>

    <v-snackbar v-model="snackbar" centered :color="color">
      {{ text }}

      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>

    <!-- Add an expense -->
    <!-- <v-dialog
      transition="dialog-bottom-transition"
      max-width="600"
      v-model="dialog"
    >
      <v-card>
        <v-toolbar color="primary" dark>New Expense</v-toolbar>
        <v-card-text class="pb-0">
          <v-col cols="12">
            <v-text-field outlined v-model="new_expense.name">
              <template v-slot:label>
                <p class="mb-0 font-weight-bold text-h4">Expense Name</p>
              </template>
            </v-text-field>
          </v-col>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn color="red" @click="dialog = false" elevation="0">Close</v-btn>
          <v-btn
            color="primary"
            @click="addNewExpense"
            elevation="0"
            :disabled="!new_expense.name"
            >Add</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog> -->
  </v-container>
</template>


<script>
export default {
  data: () => ({
    text: "",
    loading: true,
    snackbar: false,
    searchuser: "",
    search: "",
    color: "",
    text: "",

    all_expenses: [],

    addedd_expenses: [],

    dialog: false,
    new_expense: {
      is_active: true,
    },

    expense: null,
    user: {},
  }),

  methods: {
    initialize() {
      axios
        .get(`/manager/showexpense/`)
        .then((response) => {
          if (response.data.success && Array.isArray(response.data.data)) {
            this.all_expenses = response.data.data
              .filter((element) => element.is_active)
              .reverse();
            this.loading = false;
          } else {
            this.loading = false;
          }
        })
        .catch((err) => {
          this.loading = false;
        });
    },

    customFilter(item, queryText, itemText) {
      const textOne = item.expense_name.toLowerCase();
      const textTwo = item.expense_name.toLowerCase();
      const searchText = queryText.toLowerCase();

      return (
        textOne.indexOf(searchText) > -1 || textTwo.indexOf(searchText) > -1
      );
    },

    onSelect() {
      this.all_expenses = this.all_expenses.filter(
        (element) => element.expense_name != this.expense.expense_name
      );
      this.addedd_expenses.unshift(this.expense);
      this.expense = {};
    },

    onCreateVoucher() {
      // Check validity
      let isValid = true;
      for (const element of this.addedd_expenses) {
        if (!element.amount || isNaN(+element.amount)) {
          isValid = false;
          break;
        }
      }

      if (isValid) {
        // Body of the request
        let payload = [];
        for (const element of this.addedd_expenses) {
          let new_element = {
            expense_id: element.id,
            amount: Number(element.amount),
            uid: this.user.user_id,
            note : typeof(element.input_note) == 'undefined' ? '' : element.input_note
          };
          payload.push(new_element);
        }

        axios
          .post("/manager/expense_insert/", payload)
          .then((response) => {
            if (response.data.success) {
              this.all_expenses = [];
              this.addedd_expenses = [];
              this.initialize();
              this.createSnackbar("Expenses has been added successfully !");
            } else {
              this.createSnackbar("Something went wrong !", "warning");
            }
          })
          .catch((err) => {
            this.createSnackbar("Something went wrong !", "warning");
          });
      } else {
        this.createSnackbar("Please,provide valid information.", "warning");
      }
    },

    onDeleteExpense(item) {
      this.addedd_expenses = this.addedd_expenses.filter(
        (element) => element.id != item.id
      );
      this.all_expenses.push(item);
    },

    createSnackbar(text, color = "primary") {
      this.text = text;
      this.color = color;
      this.snackbar = true;
    },
  },

  mounted() {
    this.initialize();

    this.user = JSON.parse(localStorage.getItem("user"));
  },
};
</script>


<style >
.v-data-table > .v-data-table__wrapper > table > tbody > tr > th,
.v-data-table > .v-data-table__wrapper > table > thead > tr > th,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > th {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-size: 1.2rem;
  height: 48px;
}

.amount_input {
  border: 1px solid black;
  padding: 5px;
}

.amount_input:focus {
  outline: none;
}
</style>


